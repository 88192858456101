import React from "react"
import { Link } from "gatsby"
import headerStyles from "../styles/components/header.module.scss"
import Horse from "../components/IMG_3361.jpg"
// import Colville from "../components/alex-colville-to-prince-edward-island-F6IWP.jpg"

export default function Header(props) {
  return (
    <header
      className={`${headerStyles.header} ${props.page === 'info' &&
        headerStyles.info_page}`}
    >
      <nav
        className={headerStyles.header__nav}
        role="navigation"
        aria-label="main navigation"
      >
        <div style={{ marginBottom: '2rem' }}>
          {/* <Link to="/"> */}
            Hayden Betts
          {/* </Link> */}
        </div>
        <div class="social-list" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ marginBottom: '2rem' }}>
          I'm currently studying at the CUNY Craig Newmark School of Journalism

          </div>
          <div style={{ marginBottom: '2rem' }}>
            haydenbetts@gmail.com
          </div>
          <div>
            <div>
              <a style={{ textDecoration: 'underline' }} href="https://www.linkedin.com/in/haydenbetts/">LinkedIn</a> {" "}
            </div>
            <div>
              <a style={{ textDecoration: 'underline' }} href="https://www.twitter.com/haydenbetts/">Twitter</a> {" "}
            </div>
          </div>
        </div>
        {/* <div>
          <h1>
            <Link
              to={
                props.page === 'info'
                  ? "/"
                  : "/info"
              }
              activeClassName={headerStyles.navItemActive}
            >
              {props.page === 'info'
                ? "close"
                : "info"}
            </Link>
          </h1>
        </div> */}
      </nav>
    </header>
  )
}